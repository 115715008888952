import { mapState } from "vuex";

var phoneType;
export default  {
    computed: mapState(['userInfo','systemConfig']),
    data(){
        return {
            originalHeight:window.innerHeight,
            isKeyboardShow:false,
        }
    },
    created(){
        phoneType = function(){
            const userAgent = typeof window === 'object' ? window.navigator.userAgent : '';
            if (/android/i.test(userAgent)) {
                return 1; 
            } else if (/iPhone|iPod|iPad/i.test(userAgent)) {
                return 2; 
            }
        }();
        if (phoneType == 1) {
            // 获取窗口的高度
            window.addEventListener('resize', this.onResize);
        }
        if (phoneType == 2) {
            // iOS系统
            window.addEventListener('focusin', this.onFocusin);
            window.addEventListener('focusout', this.onFocusout);
        }
    },
    destroyed(){
        if (phoneType == 1) {
            // 获取窗口的高度
            window.removeEventListener('resize', this.onResize);
        }
        if (phoneType == 2) {
            // iOS系统
            window.removeEventListener('focusin', this.onFocusin);
            window.removeEventListener('focusout', this.onFocusout);
        }
    },
    methods:{
        onResize(){
            const resizeHeight = window.innerHeight;
            this.isKeyboardShow = this.originalHeight - resizeHeight > 50;
        },
        onFocusin(){
            this.isKeyboardShow = true;
        },
        onFocusout(){
            this.isKeyboardShow = false;
        },
    }
};