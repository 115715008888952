<template>
	<div :class="'wx-popup wx-popup-'+(mode||'center')" :style="'z-index:'+(zIndex||500)+';'+(visible === false?'display:none;':'')" >
		<div class='wx-popup-box' :style="boxStyle">
			<Icon class="close" type="md-close" data-type="close" @click="handleTriggerEvent" v-if="showClose"/>
			<div class="wx-popup-header" v-if="title">
				<div>{{title}}</div>
			</div>
			<div class="wx-popup-content" :style="contStyle">
				<slot></slot>
			</div>
			<div class="wx-popup-footer" v-if="showFooter">
				<button :style="'width:50%;color:'+cancelColor" data-type="cancel" @click="handleTriggerEvent" v-if="showCancel">{{cancelText ||'取消'}}</button>
				<button type="default" :style="'width:'+(showCancel?'50%':'100%')+';color:'+(confirmColor || '#07c160')" data-type="confirm" @click="handleTriggerEvent">{{confirmText ||'确定'}}</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			hidden: {           
			  type: Boolean,
			  default: true
			},
			title: {           
			  type: String,
			  default: '系统提示'
			},
			showFooter:{
			  type: Boolean,
			  default: true
			},
			showCancel:{
			  type: Boolean,
			  default: true
			},
			showClose:{
			  type: Boolean,
			  default: false
			},
			cancelText:{
			  type: String,
			  default: null
			},
			cancelColor:{
			  type: String,
			  default: null
			},
			confirmText:{
			  type: String,
			  default: null
			},
			confirmColor:{
			  type: String,
			  default: null
			},
			mode:{
			  type: String,
			  default: 'center'
			},
			backgroundColor:{
			  type: String,
			  default: null
			},
			zIndex:{
			  type: Number,
			  default: 1000
			},
			isUrl:{
			  type: Boolean,
			  default: false
			},
			bodyStyle:{
			  type: [Object,String],
			  default: null
			},
		},
		data(){
			return {
				visible: !this.hidden,
				boxStyle:'',
				contStyle:'',
			}
		},
		watch:{
			"$props.hidden"(v){
				this.visible = !v;
			},
			"$props.bodyStyle"(v){
				this.initStyle();
			},
			"$props.backgroundColor"(v){
				this.initStyle();
			},
			visible(v){
				if(v === true) this.initStyle();
			},
		},
		created(){
			if(this.visible === true)this.initStyle();
		},
		methods: {
			initStyle(){
				var style = this.bodyStyle || '' ,backgroundColor = this.backgroundColor || '#ffffff';
				if(!(style instanceof Object)){
					style = this.$util.stringToObject(style , ';' , ':' , true);
				}
				style.height = style.height || 'auto';
				style.width = !style.width && style.width !== 0 ? '80%' : style.width;
				var contStyle = {};
				for(let skey in style){
					if(['height','width','background'].indexOf(skey.split(/(?=[A-Z])/)[0]) === -1){
						contStyle[skey] = style[skey];
						delete style[skey];
					}
				}
				this.boxStyle = {...style,backgroundColor};
				this.contStyle = contStyle;
			},
			async handleTriggerEvent(e){
				let {type} = e.target.dataset;
				this.$emit(type || 'close');
				if((type || 'close') !== 'close')this.$emit('close');
				this.visible = false;
			},
			show(){
				this.visible = true;
			},
			hide(){
				this.$emit('close');
				this.visible = false;
			},
		}
	};
</script>

<style>
button {
	-webkit-tap-highlight-color: transparent;
	background-color: #f8f8f8;
	border-radius: 5px;
	box-sizing: border-box;
	color: #000;
	cursor: pointer;
	display: block;
	font-size: 17px;
	line-height: 1.41176471;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	position: relative;
	text-align: center;
	text-decoration: none;
	font-weight: 700;
	padding: 8px 24px;
}
.wx-popup {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
}
.wx-popup-center{
  align-items: center;
}
.wx-popup-box{
  position: relative;
  display: flex;
  flex-direction: column;
}
.wx-popup-center .wx-popup-box{
  min-width: 50%;
  max-width: 100%;
  min-height: 10%;
  max-height: 100%;
  border-radius: 5px;
}
.wx-popup-box .close{
  position: absolute;
  width:20px;
  height:20px;
  color: #bbbbbb;
  border:1px solid #cccccc;
  border-radius: 50%;
  right: -10px;
  top: -10px;
  background-color: #ffffff;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
}

.wx-popup-bottom{
  align-items: flex-end;
}
.wx-popup-bottom .wx-popup-box{
  width: 100%;
  width: 100%;
  border-radius: 5px 5px 0 0;
}
.wx-popup-top{
  align-items: flex-start;
}
.wx-popup-top .wx-popup-box{
  width: 100%;
  width: 100%;
  border-radius: 0 0 5px 5px;
}
.wx-popup-bottom .wx-popup-box .close,.wx-popup-top .wx-popup-box .close{
  border:none;
  right: 0;
  top: 0;
}
.wx-popup-header{
  text-align: center;
  border-bottom:1px solid #cccccc;
  padding:10px;
}
.wx-popup-content{
  padding:20px 10px;
  flex-grow: 1;
}
.wx-popup-footer{
  border-top:1px solid #cccccc;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wx-popup-footer button{
  border-radius: 0;
  min-width: 30vw;
}
.wx-popup-footer button:first-child{
  border-bottom-left-radius:5px;
}
.wx-popup-footer button:last-child{
  border-bottom-right-radius:5px;
}
.wx-popup-footer button + button{
  border-left:1px solid #cccccc;
}
</style>