
import util from './util.main';

var _utf8_encode = function (e) {e = e.toString().replace(/rn/g, "n");var t = "";for (var n = 0; n < e.length; n++) {let r = e.charCodeAt(n);if (r < 128) {t += String.fromCharCode(r);} else if (r > 127 && r < 2048) {t += String.fromCharCode(r >> 6 | 192);t += String.fromCharCode(r & 63 | 128);} else {t += String.fromCharCode(r >> 12 | 224);t += String.fromCharCode(r >> 6 & 63 | 128);t += String.fromCharCode(r & 63 | 128);}} return t;}

var _utf8_decode = function(e) {var t = "", n = 0;while (n < e.length) {let r = e.charCodeAt(n);if (r < 128) {t += String.fromCharCode(r);n++;} else if (r > 191 && r < 224) {let c2 = e.charCodeAt(n + 1);t += String.fromCharCode((r & 31) << 6 | c2 & 63);n += 2;} else {let c2 = e.charCodeAt(n + 1);let c3 = e.charCodeAt(n + 2);t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);n += 3;}} return t;}

export default {
	...util,
    promise (c,v,t) { return new Promise((resolve, reject) => {try{c instanceof Function ? c({resolve, reject},...v) : (t || window)[c]({...v, success(...a){v.success instanceof Function && v.success(...a); resolve(...a)}, fail(...a){v.fail instanceof Function && v.fail(...a);reject(...a)}, })}catch(e){reject(e)} }); },
	/**
	 * base64_encode
	 * @param e
	 * @returns {string}
	 */
	encode: function (e) {var t = "", f = 0;e = _utf8_encode(e);while (f < e.length) {let n = e.charCodeAt(f++);let r = e.charCodeAt(f++);let i = e.charCodeAt(f++);let s = n >> 2;let o = (n & 3) << 4 | r >> 4;let u = (r & 15) << 2 | i >> 6;let a = i & 63;if (isNaN(r)) {u = a = 64;} else if (isNaN(i)) {a = 64;}t = t + KS.charAt(s) + KS.charAt(o) + KS.charAt(u) + KS.charAt(a);}return t;},
	/**
	 * base64_decode
	 * @param e
	 * @returns {string}
	 */
	decode: function (e) {var t = "", f = 0;e = e.toString().replace(/[^A-Za-z0-9+/=]/g, "");while (f < e.length) {let s = KS.indexOf(e.charAt(f++));let o = KS.indexOf(e.charAt(f++));let u = KS.indexOf(e.charAt(f++));let a = KS.indexOf(e.charAt(f++));let n = s << 2 | o >> 4;let r = (o & 15) << 4 | u >> 2;let i = (u & 3) << 6 | a;t = t + String.fromCharCode(n);if (u !== 64) {t = t + String.fromCharCode(r);}if (a !== 64) {t = t + String.fromCharCode(i);}}t = _utf8_decode(t);return t;},
	/**
	 * 判断是否URL
	 * @param {Object} str
	 */
	isUrl:function (str) {var v = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$', 'i'); return v.test(str); },
	/**
	 * 设置、获取、删除缓存
	 * @param {string} key 
	 * @param {string} value 
	 * @param {int} timeout 
	 * @returns 
	 */
	localStorage: function(key , value = '' , timeout = -1){if(value === null)return localStorage.removeItem(key); if(value === ''){let _value = localStorage.getItem(key);if(_value)_value = JSON.parse(util.decrypt(_value , key , timeout)); return _value; }return localStorage.setItem(key, util.encrypt(JSON.stringify(value) , key));},
	
	stringToObject:function(str , split , split2 ,isCamelCase = false, _default = {},call = null){call = call instanceof Function?call:(v)=>{return v;};if(typeof str === 'object')return str;if(str && typeof str !== 'function'){split = split !== undefined && split !== null ? split : ',';let ss = str.toString().split(split);if(split2 !== undefined && split2 !== null){let Obj = {};for (const ssKey in ss) {let ss1 = ss[ssKey].split(split2);var k = ss1[0].trim();if(k === '')continue;Obj[isCamelCase?this.toCamelCase(k):k] = ss1[1] === undefined ? null : (ss1[1] === 'true' ? true : (ss1[1] === 'false' ? false : call(ss1[1]).trim()));}return Obj;}return ss;}return _default === undefined ? {} : _default;},
	/**
	 * 参数解析
	 * @param query
	 * @returns {{}}
	 */
	parseParams:function(params){if(params.indexOf('&') === -1 && params.indexOf('=') === -1)return {};let index = params.indexOf('?');return util.stringToObject(index > -1 ? params.substr(index + 1 , params.length - index - 1) : params , '&' , '=' , false , {} ,util.$decode);},
	/**
	 * 参数对象转字符
	 * @param query
	 * @returns {string}
	 */
	buildQuery : function(query) {return Object.entries(query).reduce((result, entry) => {result.push(entry.join('='));return result;}, []).join('&');},
	/**
	 * 驼峰大写
	 * @param {string} str 
	 * @returns 
	 */
	toCamelCase:function(str , isFirstCase = false) {var s = str.replace(/[-_\s]+(.)?/g, (match, group1) => group1 ? group1.toUpperCase() : '');return isFirstCase ? s.slice(0, 1).toUpperCase() + s.slice(1) : s;},
	/**
	 * 判断是否ios系统
	 * @returns boolean
	 */
	isIos:function () { const userAgent = navigator.userAgent || navigator.vendor || window.opera;return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;},
};