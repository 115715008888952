import Vue from 'vue'
import Vuex from 'vuex'
import util from './util'

Vue.use(Vuex)
const store = new Vuex.Store({
    state:{
        userInfo: {userId: 0,user_name: null,face: null},
        systemConfig: {
            "logo": "https://jiumaoyouxi.com/logo.png",
            "agreement": [
              {
                "title": "用户协议",
                "url": "https://www.jiumaoyouxi.com/?ct=zhanwu&ac=agreement&id=1"
              },
              {
                "title": "隐私政策",
                "url": "https://www.jiumaoyouxi.com/?ct=zhanwu&ac=agreement&id=2"
              }
            ],
        },
        viewConfig: {},
        showLogin:false,
        LoginType:''
    },
    actions: {
        showLogin({state} ,data){
            if(data === true || data === false){
                state.showLogin = data === true;
                state.LoginType = '';
            }else if(typeof data === 'string'){
                state.showLogin = true;
                state.LoginType = data;
            }
        },
        setUserInfo({state} ,data){
            if(data === null){
                state.userInfo = {userId: 0,user_name: null,face: null};
                util.localStorage("authToken",null);
                util.localStorage("userInfo",null);
            }else{
                state.userInfo = {...state.userInfo,...data};
            }
            if(state.userInfo.userId){
                let {authToken,...userInfo} = state.userInfo;
                util.localStorage("userInfo",userInfo);
            }
            if(state.userInfo.authToken){
                util.localStorage("authToken",state.userInfo.authToken);
            }
        }
    }
});
export default store