/*
 * @Author: 大盗芷紫 15108202413@139.com
 * @Date: 2024-06-24 20:33:54
 * @LastEditors: lcx978 15108202413@139.com
 * @LastEditTime: 2024-07-23 23:08:10
 * @FilePath: \turntable\src\core\router.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
var routes = [
    {
        path: '/',
        name: '抽奖',
        component: () => import('@/views/Turntable.vue')
    },
    {
        path: '/scan_login',
        name: '扫码登录',
        component: () => import('@/views/ScanQrcode.vue')
    },
    {
        path: '/orderTip',
        name: '订单提示',
        component: () => import('@/views/orderTip.vue')
    }
];
const router = new VueRouter({
    mode: 'hash',
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
});
var isInit = true;
router.beforeEach(async (to, from, next) => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    updateTitle(to);
    if(isInit){
        isInit = false;
        next({...to,params:{...to.meta.params}});
    }else{
        next();
    }
})
router.afterEach((to) => {
});
document.title = '九猫云';
/**
 * 更新浏览器标题
 * @param route 路由
 */
function updateTitle(route) {
    if (!route.path.startsWith('/redirect/')) {
        let names = [];
        if(route && route.params.title){
            names.push(route.params.title);
        }else if (route && route.meta && route.meta.title) {
            names.push(route.meta.title);
        }else if (route && route.name) {
            names.push(route.name);
        }
        const appName = process.env.VUE_APP_NAME || '九猫云';
        names.push(appName);
        document.title = names.join(' | ');
    }
}
export default router
