<template>
  <div id="app">
    <router-view />
    <auth-login ref="authLogin" @loginSuccess="getUserInfo"></auth-login>
  </div>
</template>
<script>
import authLogin from '@/components/authLogin.vue';

export default {
	components: {
		authLogin
	},
  created(){
    var token = this.$util.localStorage("authToken",'');

    if(token)this.getUserInfo();

  },
  methods:{
    getUserInfo(){
      this.$http.post('/api/user/info').then(({data})=>{
				if(data.code === 200){
          this.$store.dispatch('setUserInfo',data.data);
        }else{
          this.$store.dispatch('setUserInfo',null);
        }
			})
    },

  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.page_container {
  width: 100vw;
  height: 100vh;
	background-image: url("@/assets/imgs/bj.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
  overflow: hidden;
}
.ivu-input-prefix, .ivu-input-suffix{
  width: auto !important;
}
.ivu-modal-wrap{
    display: flex;
    align-items: center;
    justify-content: center;

    .ivu-modal{
        top: 0;
        .ivu-modal-confirm-body{
          padding-left: 0;
        }
    }
}

.ivu-table .table-warn-row td{
    background-color: #fff5f5;
	color: #fa795e;
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
  background: #78b4b4;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
  border-radius: 10px;
  background: #ededed;
}
</style>
